'use client';

import Loading from '@/app/(dynamic)/loading';
import { fetchScriptureSearch } from '@/lib/helpers';
import { ScriptureSearchResults } from '@/types/sermons';
import { useEffect, useState } from 'react';
import { useAppState } from '../providers/state-provider';
import Pagination from '../search/pagination';
import SearchResult from '../search/search-result';

export default function InlineSearchResults({
  pericope,
  type,
  tab,
  page: parentPage,
}: {
  pericope: string;
  type: string;
  tab: string;
  page: number;
}) {
  const {
    state: { token, isLoading },
  } = useAppState();
  const [searchResults, setSearchResults] = useState<ScriptureSearchResults>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(parentPage);
  const perPage = type === 'Illustrations' ? 50 : 25;

  useEffect(() => {
    setPage(parentPage);
  }, [parentPage]);

  useEffect(() => {
    if (!isLoading) {
      setLoading(true);
      (async () => {
        const results = await fetchScriptureSearch(
          0,
          0,
          pericope,
          type,
          tab,
          page,
          token,
          false,
        );
        setSearchResults(results);
        setLoading(false);
      })().catch(console.error);
    }
  }, [pericope, type, tab, page, token, isLoading]);

  return (
    <section className='border border-neutral-300 p-4'>
      {searchResults && !loading && (
        <>
          <div className='mb-4 flex items-center justify-between'>
            <div className='text-sm text-neutral-500'>
              Showing{' '}
              {(searchResults.results?.length || 0) > 0 && (
                <>
                  <span className='font-bold'>{(page - 1) * perPage + 1}</span>{' '}
                  to{' '}
                  <span className='font-bold'>
                    {(searchResults.total || 0) < page * perPage
                      ? searchResults.total || 0
                      : page * perPage}
                  </span>{' '}
                  of{' '}
                </>
              )}
              <span className='font-bold'>{searchResults.total || 0}</span>{' '}
              results
            </div>
            <Pagination
              page={page}
              lastPage={searchResults.lastPage || 1}
              isDynamic
              setPage={(page) => setPage(page)}
            />
          </div>
          {searchResults.results?.map((result, index) => (
            <>
              {index === 0 && <hr className='my-4' />}
              <SearchResult
                result={result}
                index={index + (page - 1) * perPage + 1}
                allIds={searchResults.allIds}
                type='Sermon Prep'
                hideScripture
                isPopup
              />
              <div className='clear-both'></div>
              <hr className='my-4' />
            </>
          ))}
          {(searchResults.results?.length || 0) > 0 && (
            <div className='mb-4 flex items-center justify-between'>
              <div className='text-sm text-neutral-500'>
                Showing{' '}
                <span className='font-bold'>{(page - 1) * perPage + 1}</span> to{' '}
                <span className='font-bold'>
                  {(searchResults.total || 0) < page * perPage
                    ? searchResults.total || 0
                    : page * perPage}
                </span>{' '}
                of <span className='font-bold'>{searchResults.total || 0}</span>{' '}
                results
              </div>
              <Pagination
                page={page}
                lastPage={searchResults.lastPage || 1}
                isDynamic
                setPage={(page) => setPage(page)}
              />
            </div>
          )}
        </>
      )}
      {!searchResults && !loading && <div>Error loading results</div>}
      {loading && <Loading />}
    </section>
  );
}
