'use client';

import {
  isLectionaryType,
  LECTIONARY_TYPES_TAB,
  LECTIONARY_TYPES_TABS,
} from '@/types/sermons';
import cx from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useAppState } from '../providers/state-provider';

export default function LectionaryTypesTabs({
  lectionary,
}: {
  lectionary?: LECTIONARY_TYPES_TAB;
}) {
  const {
    state: { searchParams, user },
  } = useAppState();

  const qLectionary = searchParams.get('lectionary');
  const lectionaryType: LECTIONARY_TYPES_TAB = isLectionaryType(qLectionary)
    ? qLectionary
    : lectionary ||
      (user?.lectionaryCalendarFavorite === 'C'
        ? 'Roman Catholic Lectionary'
        : 'Revised Common Lectionary');

  const [tab, setTab] = useState(lectionaryType);

  useEffect(() => {
    setTab(lectionaryType);
  }, [lectionaryType]);

  const pathname = usePathname();

  const queryString = new URLSearchParams();
  for (const [key, value] of searchParams.entries()) {
    if (key !== 'lectionary') {
      queryString.set(key, value);
    }
  }

  const qTab = searchParams.get('lectionary');
  useEffect(() => {
    if (LECTIONARY_TYPES_TABS.includes(qTab as LECTIONARY_TYPES_TAB)) {
      setTab(qTab as LECTIONARY_TYPES_TAB);
    }
  }, [qTab]);

  return (
    <nav className='bg-sermons-white h-10 w-full overflow-auto border border-b-0 border-neutral-300 text-black'>
      <div
        className='grid h-full min-w-[calc(768px_-_2rem_-_4px)] whitespace-nowrap'
        style={{
          gridTemplateColumns: `repeat(${LECTIONARY_TYPES_TABS.length}, minmax(0, 1fr)`,
        }}
      >
        {LECTIONARY_TYPES_TABS.map((currentTab, index) => {
          queryString.set('lectionary', currentTab);
          return (
            <div
              key={currentTab}
              className={cx(
                'flex h-full cursor-pointer items-center justify-center',
                {
                  'font-bold': tab === currentTab,
                  'border-l border-neutral-300': index !== 0,
                },
              )}
            >
              {tab === currentTab && (
                <h1>
                  <Link
                    className='flex size-full items-center justify-center'
                    href={`${pathname}?${queryString}`}
                  >
                    {currentTab}
                  </Link>
                </h1>
              )}
              {tab !== currentTab && (
                <Link
                  className='flex size-full items-center justify-center'
                  href={`${pathname}?${queryString}`}
                >
                  {currentTab}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </nav>
  );
}
