'use client';

import { fetchCurrentNewWeek, isError } from '@/lib/helpers';
import {
  isLectionaryType,
  LECTIONARY_TYPES_MAP,
  LECTIONARY_TYPES_TAB,
  LECTIONARY_YEARS_TAB,
  LECTIONARY_YEARS_TABS,
  LectionaryWeeks,
} from '@/types/sermons';
import cx from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useNotifications } from '../providers/notification-provider';
import { useAppState } from '../providers/state-provider';

export default function LectionaryYearsTabs({ year }: { year?: string }) {
  const {
    state: { searchParams, searchParamsReady, token },
  } = useAppState();

  const addNotification = useNotifications();

  const [tab, setTab] = useState(
    year ? (`Year ${year.toUpperCase()}` as LECTIONARY_YEARS_TAB) : undefined,
  );

  const qTab = searchParams.get('tab');
  const tabYear = ((qTab && qTab.slice(-1).toLowerCase()) || null) as
    | keyof LectionaryWeeks
    | null;

  const qLectionary = searchParams.get('lectionary');
  const lectionaryType: LECTIONARY_TYPES_TAB = isLectionaryType(qLectionary)
    ? qLectionary
    : 'Revised Common Lectionary';

  useEffect(() => {
    if (searchParamsReady) {
      if (!tabYear) {
        (async () => {
          const currentWeeks = await fetchCurrentNewWeek(
            undefined,
            LECTIONARY_TYPES_MAP[lectionaryType],
            token,
          );
          if (isError(currentWeeks)) {
            addNotification({ type: 'error', message: currentWeeks.message });
            return;
          }
          setTab(
            `Year ${
              currentWeeks[
                Object.keys(currentWeeks)[0] as keyof typeof currentWeeks
              ].year || 'A'
            }` as LECTIONARY_YEARS_TAB,
          );
        })().catch(console.error);
      } else {
        setTab(`Year ${tabYear.toUpperCase()}` as LECTIONARY_YEARS_TAB);
      }
    }
  }, [token, searchParams, searchParamsReady]);

  const pathname = usePathname();

  const queryString = new URLSearchParams();
  for (const [key, value] of searchParams.entries()) {
    if (key !== 'tab') {
      queryString.set(key, value);
    }
  }

  useEffect(() => {
    if (LECTIONARY_YEARS_TABS.includes(qTab as LECTIONARY_YEARS_TAB)) {
      setTab(qTab as LECTIONARY_YEARS_TAB);
    }
  }, [qTab]);

  return (
    <nav className='h-10 w-full overflow-auto bg-sermons-dark font-bold text-white'>
      <div
        className='grid min-w-[calc(768px_-_2rem_-_4px)] whitespace-nowrap'
        style={{
          gridTemplateColumns: `repeat(${LECTIONARY_YEARS_TABS.length}, minmax(0, 1fr)`,
        }}
      >
        {LECTIONARY_YEARS_TABS.map((currentTab) => {
          queryString.set('tab', currentTab);
          return (
            <div
              key={currentTab}
              className={cx(
                'flex h-10 items-center justify-center border-b-4 cursor-pointer',
                {
                  'border-b-sermons-highlight': tab === currentTab,
                  'border-b-sermons-dark': tab !== currentTab,
                },
              )}
            >
              <Link
                className='flex size-full items-center justify-center'
                href={`${pathname}?${queryString}`}
              >
                {currentTab}
              </Link>
            </div>
          );
        })}
      </div>
    </nav>
  );
}
