'use client';

import { useSetLinksTargetBlank } from '@/lib/client-helpers';
import { updateNewLectionaryResources } from '@/lib/helpers';
import { NewWeek } from '@/types/sermons';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { useNotifications } from '../providers/notification-provider';
import { useAppState } from '../providers/state-provider';
import Button from './button';
import MyEditor from './editor';

export default function LectionaryResources({
  week,
  onSave,
}: {
  week: NewWeek;
  onSave?: () => void;
}) {
  const {
    state: { user, token, isLoading, isBrowser },
  } = useAppState();
  const addNotification = useNotifications();

  const [editingResources, setEditingResources] = useState(false);
  const [resourcesText, setResourcesText] = useState(week.resources || '');

  const contentRef = useRef<HTMLDivElement>(null);

  const setLinksTargetBlank = useSetLinksTargetBlank();

  useEffect(() => {
    if (contentRef.current && !isLoading && !user && isBrowser) {
      const links = [
        ...contentRef.current.querySelectorAll(
          'a:not([href="javascript:void(0)"]), a[target="_blank"]',
        ),
      ] as HTMLAnchorElement[];
      if (links.length === 0) {
        return;
      }
      for (const link of links) {
        link.removeAttribute('target');
        link.removeAttribute('rel');
        link.removeAttribute('href');
        link.style.opacity = '0.75';
        link.style.cursor = 'not-allowed';
        link.outerHTML.replace(/^<a/i, '<span').replace(/<\/a>$/i, '</span>');
      }
    }
  });

  return (
    <div className='p-4'>
      {user?.isAdmin && !editingResources && (
        <>
          <Button
            className='float-right ml-2 inline-flex size-10 items-center justify-center text-3xl'
            onClick={(e) => {
              e.preventDefault();
              setEditingResources(true);
            }}
          >
            <BsPencilSquare />
          </Button>
        </>
      )}
      {editingResources && (
        <div>
          <MyEditor
            data={resourcesText}
            onChange={(_, editor) => {
              setResourcesText(editor.getData());
            }}
            onReady={(editor) => {
              editor.ui
                .getEditableElement()
                ?.parentElement?.classList.add('sermon-content');
            }}
          />
          <div className='mt-4 flex justify-end'>
            <Button
              onClick={async () => {
                if (!token) {
                  return;
                }

                const result = await updateNewLectionaryResources(
                  week.id,
                  resourcesText,
                  token,
                );
                if ('statusCode' in result) {
                  addNotification({
                    type: 'error',
                    message:
                      typeof result.message === 'string'
                        ? result.message
                        : result.message.join('\n'),
                  });
                } else {
                  addNotification({
                    type: 'success',
                    message: result.success,
                  });
                  setEditingResources(false);
                  onSave?.();
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {!editingResources &&
        (resourcesText ? (
          <>
            {!isLoading && !user && isBrowser && (
              <div className='mb-4 text-center'>
                The following links will be made active with a membership to
                Sermons.com.
                <br />
                <Link
                  className='font-bold text-sermons-dark hover:underline'
                  href='/user/signup'
                >
                  CLICK HERE
                </Link>{' '}
                here to join
              </div>
            )}
            <div
              ref={contentRef}
              dangerouslySetInnerHTML={{
                __html: user
                  ? setLinksTargetBlank(resourcesText)
                  : resourcesText,
              }}
              className='sermon-content'
            />
          </>
        ) : (
          <div>There are no additional resources.</div>
        ))}
      <div className='clear-both' />
    </div>
  );
}
