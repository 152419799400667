'use client';

import { LECTIONARY_TOOLS_TAB, LECTIONARY_TOOLS_TABS } from '@/types/sermons';
import cx from 'classnames';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export default function LectionaryToolsTabs({
  tab: parentTab,
  setTab: parentSetTab,
}: {
  tab: LECTIONARY_TOOLS_TAB;
  setTab?: Dispatch<SetStateAction<LECTIONARY_TOOLS_TAB>>;
}) {
  const [tab, setTab] = useState<LECTIONARY_TOOLS_TAB>(parentTab);

  useEffect(() => {
    setTab(parentTab);
  }, [parentTab]);

  return (
    <nav className='h-10 w-full overflow-auto bg-sermons-dark font-bold text-white'>
      <div
        className='grid min-w-[calc(768px_-_2rem_-_4px)] whitespace-nowrap'
        style={{
          gridTemplateColumns: `repeat(${LECTIONARY_TOOLS_TABS.length}, minmax(0, 1fr)`,
        }}
      >
        {LECTIONARY_TOOLS_TABS.map((currentTab) => (
          <div
            key={currentTab}
            className={cx(
              'flex h-10 cursor-pointer items-center justify-center border-b-4',
              {
                'border-b-sermons-highlight': tab === currentTab,
                'border-b-sermons-dark': tab !== currentTab,
              },
            )}
          >
            <span
              className='flex size-full items-center justify-center'
              onClick={(e) => {
                const clickedTab = (e.target as HTMLSpanElement)
                  .textContent as LECTIONARY_TOOLS_TAB;
                setTab(clickedTab);
                parentSetTab?.(clickedTab);
              }}
            >
              {currentTab}
            </span>
          </div>
        ))}
      </div>
    </nav>
  );
}
